import { render, staticRenderFns } from "./organizationManagerList.vue?vue&type=template&id=482f0239&scoped=true&"
import script from "./organizationManagerList.vue?vue&type=script&lang=js&"
export * from "./organizationManagerList.vue?vue&type=script&lang=js&"
import style0 from "./organizationManagerList.vue?vue&type=style&index=0&id=482f0239&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482f0239",
  null
  
)

export default component.exports