<template>
  <div>
    <el-table
      class="tableBox"
      stripe
      highlight-current-row
      :data="data"
      size="mini"
      row-key="departmentId"
      :default-expand-all="true"
      lazy
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      max-height="800px"
    >
      <el-table-column
        prop="departmentName"
        label="组织名称"
        sortable
        width="1000"
      ></el-table-column>
      <el-table-column prop="" label="操作" >
        <template slot-scope="scope">
          <!-- 三级状态下不可删除 -->
          <!-- <span class="greyColor mr10 pointer" v-if="scope.row.departmentId.length>=12">新增</span> -->
          <span class="greenColor mr10 pointer" @click="addDepart(scope.row, 1)"
            >新增</span
          >
          <span class="greenColor mr10 pointer" @click="addDepart(scope.row, 2)"
            >编辑</span
          >
          <span
            class="greyColor pointer"
            v-if="scope.row.departmentId === '0000'"
            >删除</span
          >
          <span class="redColor pointer" @click="remove(scope.row)" v-else
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog 
    :title="titleName" 
    :visible.sync="dialogVisible" 
    width="450px"
    :showClose="false"
    >
      <el-form :model="form">
        <el-form-item label="结构名称">
          <el-input
            maxlength="14"
            size="small"
            v-model="form.departmentName"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <div class="tips">(结构名称最多只能输入14位)</div>
        <el-form-item label="结构类型" v-if="isEdit === 2">
          <el-select
            size="small"
            style="width: 300px"
            v-model="form.departmentType"
          >
            <el-option
              v-for="(item, index) in departmentData"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelClick">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="addDepartment"
          v-if="titleName === '新增结构'"
          >确 定</el-button
        >
        <el-button size="small" type="primary" @click="editDepartment" v-else
          >确 定</el-button
        >
        <!-- editDepartment -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import {
//   AddOrUpdate,
//   getSysDepartmentList,
//   deleteData,
// } from "@/api/authorityManagement";
export default {
  data() {
    return {
      dialogVisible: false,
      titleName: "",
      form: {
        departmentName: "", //部门名称
        departmentType: "", //类型
      },
      label: "",
      data: [],
      tableData: [],
      // 部门类型
      departmentData: [],
      parentId: "",
      departmentId: "",
      departId: "",
      isEdit: 0, //编辑是否显示结构类型
    };
  },
  mounted() {
    //this.renderContent();
  },
  methods: {
    fn(arr) {
      arr.forEach((item) => {
        if (item.children) {
          this.$set(item, "label");
          item.label = item.departmentName;
          this.fn(item.children);
        }
      });
    },
    renderContent() {
      getSysDepartmentList().then((res) => {
        if (res.code == 1) {
          // console.log(res, "ddd");
          this.data = res.data;
          this.data.forEach((item) => {
            this.$set(item, "label");
            item.label = item.departmentName;
            if (item.children) {
              this.fn(item.children);
            }
          });
        }
      });
    },
    addDepart(val, num) {
      this.dialogVisible = true;
      this.departmentId = val.departmentId;
      this.parentId = val.parentId;
      console.log(val, "kkk");
      if (num == 1) {
        this.isEdit = 2;
        if (val.departmentId === "0000") {
          this.departmentData = [{ name: "园区", value: "5" }];
        } else {
          this.departmentData = [
            { name: "行政", value: "1" },
            { name: "财务", value: "2" },
            { name: "门岗", value: "3" },
            { name: "仓库", value: "4" },
            { name: "精拆解车间", value: "6" },
            { name: "粗拆解车间", value: "7" },
            { name: "动力拆解车间", value: "8" },
          ];
        }
        this.titleName = "新增结构";
        // if(val.children) {
        //   val.children[0].parentId = val.departmentId
        //   this.parentId = val.children[0].parentId
        // }
      } else if (num == 2) {
        if (val.departmentId.length <= 8) {
          this.departmentData = [{ name: "园区", value: "5" }];
        } else {
          this.departmentData = [
            { name: "行政", value: "1" },
            { name: "财务", value: "2" },
            { name: "门岗", value: "3" },
            { name: "仓库", value: "4" },
            { name: "精拆解车间", value: "6" },
            { name: "粗拆解车间", value: "7" },
            { name: "动力拆解车间", value: "8" },
          ];
        }
        if (val.departmentId.length == 4) {
          this.isEdit = 1;
        } else if (val.departmentId.length > 4) {
          this.isEdit = 2;
        }
        this.titleName = "编辑结构";
        this.form.departmentName = val.departmentName;
        this.form.departmentType = val.departmentType;
        // if(val.departmentType==="1") {
        //   this.form.departmentType = '行政'
        // } else if(val.departmentType==="2") {
        //   this.form.departmentType = '财务'
        // } else if(val.departmentType==="3") {
        //   this.form.departmentType = '门岗'
        // } else if(val.departmentType==="4") {
        //   this.form.departmentType = '仓库'
        // } else if(val.departmentType==="5") {
        //   this.form.departmentType = '园区'
        // }
        // else if(val.departmentType==="6") {
        //   this.form.departmentType = '精拆解车间'
        // } else if(val.departmentType==="7") {
        //   this.form.departmentType = '粗拆解车间'
        // } else if(val.departmentType==="8") {
        //   this.form.departmentType = '动力拆解车间'
        // }
      }
    },
    // 新增
    addDepartment() {
      let data = {
        parentId: this.departmentId,
        ...this.form,
      };
      AddOrUpdate(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("新增成功!");
          this.renderContent();
          this.dialogVisible = false;
          this.form = {
            departmentName: "", //部门名称
            departmentType: "", //类型
          };
        }
      });
    },
    // 编辑
    editDepartment() {
      let data = {
        departmentId: this.departmentId,
        ...this.form,
      };
      console.log(data);
      AddOrUpdate(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("编辑成功!");
          this.dialogVisible = false;
          this.renderContent();
          this.form = {
            departmentName: "", //部门名称
            departmentType: "", //类型
          };
        }
      });
    },
    // 删除
    remove(val) {
      // console.log(val)
      this.$confirm("确定删除此部门?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            departmentId: val.departmentId,
          };
          deleteData(data).then((res) => {
            if (res.code === 1) {
              this.$message.success("删除成功!");
              this.renderContent();
            }
          });
        })
        .catch(() => {});
    },
    // 弹框取消
    cancelClick() {
      (this.dialogVisible = false), (this.form.departmentName = "");
      this.form.departmentType = "";
    },
  },
};
</script>

<style lang="less" scoped>
.tableBox {
  width: 1400px;
  text-align: center;
  margin: 0 auto;
}
.treeBox {
  padding: 30px 30px;
}

.addDeleteBtn {
  display: inline-block;
  padding: 20px;
}

.dialog-footer {
  text-align: center;
}

.tips {
  color: rgba(255, 78, 78, 1);
  font-size: 14px;
  padding-left: 70px;
  margin-top: -10px;
  margin-bottom: 10px;
}
</style>
